import "../../common/superfans.css"
import React ,{ useEffect, useRef } from 'react';
import Qr from "./qrcode";
import { useSuperfan } from "../../../index";
import { motion } from "framer-motion";
const Index  = ()=> {
    const { setRewardToken } = useSuperfan();
    
    return (
        <motion.div
            style={{
                width : "100svw",
                height : "100svh",
                display : "flex",
                justifyContent : "center",
                alignItems : "center",
                position : "absolute",
                top : 0,
                left : 0,
                zIndex : 100,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 , transition : {duration : 0.5}}}
        >
            <div
                style={{width : "100%", height : "100%", position : "absolute", top : 0, left : 0, backgroundColor : "rgba(0,0,0,0.4)"}}
                onClick={() => setRewardToken(null)}
            />
            <Qr/>
        </motion.div>
    )
}


export default Index;
  