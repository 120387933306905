// Loading.js
import React from "react";
import Lottie from "lottie-react";
import LoadingAnimation from "./lotties/loading.json"; // Ensure correct path

const Loading = () => {
  return (
    <div
      style={{
        backgroundColor: "#f4e3c9",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          width: "100vw",
        }}
      >
        <div
          style={{
            height: "11svh",
            width: "11svh",
          }}
        >
          <Lottie
            animationData={LoadingAnimation}
            loop={true}
            height={100}
            width={100}
          />
        </div>
      </div>
    </div>
  );
};

export default Loading;
