import { useEffect } from "react";
import { isBrowser } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const DeviceChecker = () => {

    const nav = useNavigate();

    useEffect(() => {
        if (isBrowser) {
            nav("/desktopblock")
        }
    },[])
    return (
        <></>
    );
}
 
export default DeviceChecker;