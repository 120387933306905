import { get } from "animejs";
import { initializeApp } from "firebase/app";
import { getFunctions , httpsCallable } from 'firebase/functions';
import { setDoc , getFirestore, doc } from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyCvOLqEkE3S0K6NKMW6vIO5MjVdxJ4k0Zw",
    authDomain: "superfan-3a794.firebaseapp.com",
    databaseURL: "https://superfan-3a794-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "superfan-3a794",
    storageBucket: "superfan-3a794.appspot.com",
    messagingSenderId: "18328979437",
    appId: "1:18328979437:web:b6fb5190d1b2ba9445c9e4",
    measurementId: "G-JVGTV6ZQEK"
};
export const firebaseApp = initializeApp(firebaseConfig);
export const functions = getFunctions(firebaseApp);
// connectFunctionsEmulator(functions, "localhost", 5001);

export const sendEmailMgs = async({
    to,
    subject,
    text,
    html
  })=>{
    let sendEdm = httpsCallable(functions, "sendedm");
    await sendEdm({
      to,
      subject,
      text,
      html
    });
}

export const sendWhatsapp = async ({
  contentSid , contentvariable , phone
  }) => {
    let _sendwhatsapp = httpsCallable(functions, "sendwhatsappv2");
    await _sendwhatsapp({
      contentSid: contentSid,
      contentvariable: contentvariable,
      phone: phone
    });
}

export const validateOTP = async ({
  measure , code
}) => {
  let checkotp = httpsCallable(functions, "checkotp");
  let res = await checkotp({
    measure,
    code
  });
  return res;
}

export const db = getFirestore(firebaseApp);

export const sendVerificationLink = async ({ phone  })=>{

  let fullUrl = localStorage.getItem("fullUrl");
  let tokenId = Date.now().toString();
  let sendUrl = "https://api.superfan.digital/verify?tokenId=" + tokenId;
  let docRef = doc(db, "phone-verification", tokenId );
  let _data = {
    phone, 
    redirect : fullUrl,
    verificationCreateTime: Date.now()
  }

  await setDoc(docRef, _data);
  await sendWhatsapp({
    contentSid: "HX7d8b54a48526e4f24acde4fb682a9ab7",
    contentvariable : {
      1 : sendUrl,
    },
    phone
  })

  return true;
}