import { React, useEffect, useState, useRef  } from "react";
import Lottie from "lottie-react";
import SoundAnimate from "./lotties/SoundBtn.json";



const SoundBtn = ({ onToggleSound }) => {
  const [isSoundOn, setIsSoundOn] = useState(true);
  const lottieRef = useRef();

  const toggleSound = () => {
    const newSoundState = !isSoundOn;
    setIsSoundOn(newSoundState);
    onToggleSound(newSoundState);    
    
    if (lottieRef.current) {
      if (isSoundOn) {
        lottieRef.current.playSegments([0, 50], true);
      } else {
        lottieRef.current.playSegments([51, 90], true);
      }
    }
  };
//   useEffect(() => {
//     if (lottieRef.current) {
//       lottieRef.current.goToAndStop(isSoundOn ? 51 : 0, true);
//     }
//   }, [isSoundOn]);

  return (
    <div onClick={toggleSound}>
      <Lottie
        lottieRef={lottieRef}
        animationData={SoundAnimate}
        style={{ width: "100%", height: "100%" }}
        loop={false}
        autoplay={false}
      />
    </div>
  );
};

export default SoundBtn;
