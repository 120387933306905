import SpriteSheet from "react-responsive-spritesheet";
import { useState } from "react";

const ParticlerWraper = ({ children }) => {
  const [particlehub, setParticleHub] = useState({});

  return (
    <section
      onClick={(e) => {
        const { clientX, clientY } = e;
        setParticleHub((pre) => {
          let date = new Date().getTime();
          let _temp = { ...pre };
          _temp[date] = { x: clientX, y: clientY };

          return _temp;
        });
      }}
      style={{
        overflow: "hidden",
        width: "100svw",
        height: "100svh",
        position: "relative",
        zIndex: "80",
      }}
    >
      {children}
      <Particles particlehub={particlehub} setParticleHub={setParticleHub} />
    </section>
  );
};

const Particles = ({ particlehub, setParticleHub }) => {
  const onComplete = (key) => {
    let _temp = { ...particlehub };
    delete _temp[key];
    setParticleHub(_temp);
  };

  return (
    <>
      {Object.keys(particlehub).map((key) => {
        return (
          <ParticleEffect
            key={key}
            x={particlehub[key].x}
            y={particlehub[key].y}
            onComplete={() => {
              onComplete(key);
            }}
          />
        );
      })}
    </>
  );
};

const ParticleEffect = ({ x, y, onComplete }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: y,
        left: x,
        zIndex: 20,
        transform: "translate(-50%,-50%)",
        pointerEvents: "none",
      }}
    >
      <SpriteSheet
        image={"/webp/Particles.webp"} // Image URL
        widthFrame={256} //
        heightFrame={256} //
        steps={10} // Total number of frames
        fps={20} // Frames per second
        autoplay={true} // Start animation on mount
        onPause={() => {
          onComplete();
        }} // Callback when animation completes
        style={{
          width: "100%",
          height: "100%",
          zIndex: 12,
          top: 0,
          left: 0,
        
        }} // Additional styling for the <div
      />
    </div>
  );
};

export default ParticlerWraper;
