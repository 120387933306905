import {
  Input,
  Button,
  InputMobile,
  InputEmail,
  InputEmailOri,
  InputMobileOri,
  AnimateBox,
} from "../common";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { makeUseVisualState, motion } from "framer-motion";
import SUPERFANLOGO from "./logo.png";
import GoogleImg from "./google_icon.png";
import toast from "react-hot-toast";
import { useSuperfan } from "../../../superfancontext";
import { httpsCallable } from "firebase/functions";
import { doc, onSnapshot } from "firebase/firestore";
import { sendVerificationLink, db } from "../../firebase";

export const Padding = ({ children }) => {
  return (
    <div
      style={{
        padding: "0 0rem",
        backgroundColor: "black",
        height: "100%",
      }}
    >
      {children}
    </div>
  );
};

export const Pre = (props) => {
  const { selectedId, setSelectedId } = props;
  const { googleSignIn, collection } = useSuperfan();
  const nav = useNavigate();
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  const singaporeMalaysiaMobileRegex = /^\+\d{1,3}\s?\d{8,14}$/;
  const { twillioOTP } = useSuperfan();
  const [sendingOTP, setSendingOTP] = useState(false);
  const [googleLogin, setGoogleLogin] = useState(false);
  const valueRef = useRef(null);

  const handerSend = async () => {
    await sendVerificationLink({
      phone: valueRef.current,
    });
  };

  const handerClick = async () => {
    let emailOrMobile = valueRef.current;
    setSendingOTP(true);
    if (selectedId === "pre-email") {
      if (emailRegex.test(emailOrMobile)) {
        try {
          await twillioOTP();
          setSendingOTP(false);
          setSelectedId("otp");
        } catch (e) {
          setSendingOTP(false);
          alert("Error");
        }
      } else {
        toast.error("Invalid Email");
        setSendingOTP(false);
      }
    } else {
      if (singaporeMalaysiaMobileRegex.test(emailOrMobile)) {
        try {
          await handerSend();
          setSendingOTP(false);
          setSelectedId("verifyurl");
        } catch (e) {
          setSendingOTP(false);
          alert("Error");
        }
      } else {
        toast.error("Invalid Mobile Number");
        setSendingOTP(false);
      }
    }
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        delay: 1.2,
        duration: 0.8,
      }}
      style={{ height: "100%", padding: "0 2.5rem" }}
    >
      <div className="block" style={{ height: "2.2rem" }} />
      <div className="imgContainer-h" style={{ height: "1.2rem" }}>
        <img src={SUPERFANLOGO} />
      </div>

      <div className="block" style={{ height: "0.9rem" }} />

      <p style={{ textAlign: "left" }}>
        Please sign-up or sign-in below
        <br />
        to enter experience.
      </p>

      <div className="block" style={{ height: "2rem" }} />
      {/*  */}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h5>{selectedId === "pre-mobile" ? "Mobile" : "Email"}</h5>
        <h5
          //71 76 80
          style={{ color: "rgb(154 154 154)" }}
          onClick={() => {
            setSelectedId((pre) => {
              if (pre === "pre-email") {
                return "pre-mobile";
              } else {
                return "pre-email";
              }
            });
          }}
        >
          {selectedId === "pre-mobile" ? "Use Email" : "Use Mobile"}
        </h5>
      </div>

      <div className="block" style={{ height: "1rem" }} />

      {selectedId === "pre-email" && <InputEmailOri valueRef={valueRef} />}
      {selectedId === "pre-mobile" && <InputMobileOri valueRef={valueRef} />}

      <div className="block" style={{ height: "2rem" }} />

      <Button
        id="2"
        style={{
          backgroundColor: "#00BF63",
          color: "#000",
          position: "relative",
          overflow: "hidden",
        }}
        name="Login/Sign-up"
        onClick={async () => {
          // await handerClick();
          await handerClick();
        }}
      >
        {sendingOTP ? <AnimateBox /> : "Login/Sign-up"}
        {/* <AnimateBox/> */}
      </Button>

      <div className="block" style={{ height: "1.2rem" }} />

      <Button
        id="3"
        style={{
          backgroundColor: "#141617",
          color: "white",
        }}
        name="Sign in with Google"
        onClick={async () => {
          // googleSignIn(nav);
          // setGoogleLogin(true);
          setSelectedId((pre) => {
            if (pre === "pre-email") {
              return "pre-mobile";
            } else {
              return "pre-email";
            }
          });
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "0 auto",
            width: "fit-content",
            alignItems: "center",
          }}
        >
          {/* {googleLogin && <AnimateBox />}
          {!googleLogin && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="imgContainer-h" style={{ height: "1.2rem" }}>
                <img src={GoogleImg} />
              </div>
              <div style={{ width: "0.6rem" }} />
              Sign in with Google
            </div>
          )} */}

          {selectedId === "pre-email"
            ? "Sign in with Mobile"
            : "Sign in with Email"}
        </div>
      </Button>

      <div className="block" style={{ height: "9svh", maxHeight: "3.5rem" }} />
      <h6
        style={{
          color: "#505050",
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
        onClick={() => {
          // Open the PDF link in a new tab
          window.open("https://privacy.superfan.digital/", "_blank");
        }}
      >
        Privacy Policy
      </h6>
      <span
        style={{
          color: "#505050",
          whiteSpace: "nowrap",
          margin: "0 5px", // Optional: Adds space around the pipe
        }}
      >
        |
      </span>

      <h6
        style={{
          color: "#505050",
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
        onClick={() => {
          // Open the PDF link in a new tab
          window.open("https://terms.superfan.digital/", "_blank");
        }}
      >
        Terms of use
      </h6>
    </motion.div>
  );
};

export const CollectInfo = ({ setCanPlaySuperFan, afterSignInTo }) => {
  const { addInfo, collection } = useSuperfan();
  const [leftInfo, setLeftInfo] = useState(false);
  const [info, setInfo] = useState({});
  const inputRef = useRef(null);
  const { functions, user } = useSuperfan();
  const nav = useNavigate();
  const getLeftInfo = httpsCallable(functions, "getleftinfo");
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (user?.isAnonymous === false) {
      let userCollection = "users-" + collection.split("-")[0];
      let userRef = doc(db, userCollection, user.uid);
      onSnapshot(userRef, (doc) => {
        setProcessing(false);
        let requireInfoTemp = {
          name: false,
          email: false,
          phoneNumber: false,
          term: false,
        };

        let deleted = [];

        try {
          Object.keys(doc.data()).forEach((key) => {
            deleted.push(key);
            delete requireInfoTemp[key];
          });
        } catch (e) {}


        if (Object.values(requireInfoTemp).length > 0) {
          setCanPlaySuperFan(true);
          setLeftInfo(true);
          let collectInfoContainer = document.getElementById(
            "collectInfoContainer"
          );
          collectInfoContainer.style.height = "100%";
          collectInfoContainer.style.maxHeight = "45rem";
          setInfo(requireInfoTemp);
        } else {
          try {
            afterSignInTo.callBack();
            nav(afterSignInTo.to);
            setCanPlaySuperFan(false);
            setLeftInfo(false);
          } catch (e) {}
        }

        Object.keys(requireInfoTemp).forEach((key) => {
          let element = document.getElementById(key);
          element.style.display = "block";
        });

        deleted.forEach((key) => {
          let element = document.getElementById(key);
          if (element) {
            element.style.display = "none";
          }
        });
      });
    }
  }, [user?.isAnonymous]);


  useEffect(() => {
    if(user?.isAnonymous === false && leftInfo === false){
      setCanPlaySuperFan(false);
    }
  }, [leftInfo]);

  return (
    <div
      id="collectInfoContainer"
      style={{
        height: "70svh",
        padding: "0 2.5rem",
        maxHeight: "29rem",
        transition: "0.8s ease-in-out",
        opacity: leftInfo ? 1 : 0,
        transitionDuration: "1.2s",
        overflow: "scroll",
      }}
    >
      <ResponsiveContainer>
        <div className="block" style={{ height: "1.5rem" }} />
        {/* <div className="block" style={{ height: "2.2rem" }} /> */}
        {/* <div className="imgContainer-h" style={{ height: "1.2rem" }}>
          <img src={SUPERFANLOGO} />
        </div> */}
        {/* 
        <div className="block" style={{ height: "1.5rem" }} /> */}

        <p style={{ textAlign: "left" }}>
          Get your pass! Please enter your details to receive redemption link.
        </p>
        <div className="block" style={{ height: "1rem" }} />
        <Name setInfo={setInfo} />
        <Emaill setInfo={setInfo} />
        <Mobilee setInfo={setInfo} />
        <Terms setInfo={setInfo} info={info} />
        <div className="block" style={{ height: "1rem" }} />

        <Button
          id="2"
          style={{
            backgroundColor: "#00BF63",
            color: "#000",
          }}
          onClick={async () => {
            let isValid = true;
            Object.keys(info).forEach((key) => {
              switch (key) {
                case "term":
                  if (info.term === false) {
                    isValid = false;
                    return toast.error("Please agree to the terms");
                  }
                  break;
                case "name":
                  if (info.name === false || info.name.length === 0) {
                    isValid = false;
                    return toast.error("Please enter your name");
                  }
                  break;
                case "phoneNumber":
                  if (info.phoneNumber === false) {
                    isValid = false;
                    return toast.error("Please enter your phone number");
                  }

                  const singaporeMalaysiaMobileRegex =
                    /^\+\d{1,3}\s?\d{8,14}$/;
                  if (!singaporeMalaysiaMobileRegex.test(info.phoneNumber)) {
                    isValid = false;
                    return toast.error("Invalid Phone Number");
                  }
                  break;

                case "email":
                  if (info.email === false) {
                    isValid = false;
                    return toast.error("Please enter your email");
                  }
                  const emailRegex =
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                  if (!emailRegex.test(info.email)) {
                    isValid = false;
                    return toast.error("Invalid Email");
                  }
                  break;
              }
            });

            if (isValid === false) {
              return;
            }

            setCanPlaySuperFan(false);
            setProcessing(true);

            try {
              afterSignInTo.callBack();
              if (afterSignInTo.to) {
                nav(afterSignInTo.to);
              }
            } catch (e) {}

            setTimeout(() => {
              addInfo(info);
            }, 1000);
          }}
        >
          {processing ? <AnimateBox /> : "Enter"}
        </Button>
        <div className="block" style={{ height: "3rem" }} />
      </ResponsiveContainer>
    </div>
  );
};

const Emaill = ({ setInfo }) => {
  return (
    <div id="email" style={{ display: "none" }}>
      <h5 style={{ color: "white", textAlign: "left" }}>Email</h5>
      <div className="block" style={{ height: "0.5rem" }} />
      <InputEmail
        onChange={(e) => {
          setInfo((pre) => {
            return {
              ...pre,
              email: e.target.value,
            };
          });
        }}
      />
      <div className="block" style={{ height: "1rem" }} />
    </div>
  );
};

const Mobilee = ({ setInfo }) => {
  return (
    <div id="phoneNumber" style={{ display: "none" }}>
      <h5 style={{ color: "white", textAlign: "left" }}>Mobile</h5>
      <div className="block" style={{ height: "0.5rem" }} />
      <InputMobile setInfo={setInfo} />
      <div className="block" style={{ height: "1rem" }} />
    </div>
  );
};

const Name = ({ setInfo }) => {
  return (
    <div id="name" style={{ display: "none" }}>
      <h5 style={{ color: "white", textAlign: "left" }}>Name</h5>
      <div className="block" style={{ height: "0.5rem" }} />
      <Input
        placeholder={"Name"}
        type="text"
        onChange={(e) => {
          setInfo((pre) => {
            return {
              ...pre,
              name: e.target.value,
            };
          });
        }}
      />
      <div className="block" style={{ height: "1rem" }} />
    </div>
  );
};

export function Terms({ setInfo, info }) {
  const [showMore, setShowMore] = useState(false);
  const [check, setCheck] = useState({
    check1: false,
    check2: false,
    check3: false,
  });

  useEffect(() => {
    let isAll = Object.values(check).every((key) => {
      return key === true;
    });

    if (isAll) {
      setInfo((pre) => {
        return {
          ...pre,
          term: true,
        };
      });
    } else {
      setInfo((pre) => {
        return {
          ...pre,
          term: false,
        };
      });
    }
  }, [check]);

  return (
    <div id="term" style={{ display: "none" }}>
      <div className="block" style={{ height: "0.5rem" }} />
      {/* First checkbox */}
      <section
        style={{
          display: "flex",
        }}
        className="terms dontwantBold"
        id="superfanTerms"
      >
        <div
          onClick={() => {
            setCheck((pre) => {
              return {
                ...pre,
                check1: !check?.check1,
              };
            });
          }}
        >
          <div
            id="terms"
            style={{
              width: "0.8rem",
              height: "0.8rem",
              backgroundColor: "#fff",
              borderRadius: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
              marginRight: "1rem",
            }}
          >
            <div
              style={{
                width: "0.5rem",
                height: "0.5rem",
                backgroundColor: !check?.check1 ? "#fff" : "#00BF63",
                borderRadius: 0,
                border: "none",
              }}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              color: "white",
              fontSize: "1rem",
              textAlign: "left",
              lineHeight: "1.2",
              letterSpacing: "0.1rem",
            }}
          >
            you confirm you are 18 years and above;*
          </p>
        </div>
      </section>
      <div className="block" style={{ height: "0.5rem" }} />

      {/* Second checkbox */}
      <section
        style={{
          display: "flex",
        }}
        className="terms dontwantBold"
        id="superfanTerms"
      >
        <div
          onClick={() => {
            setCheck((pre) => {
              return {
                ...pre,
                check2: !check?.check2,
              };
            });
          }}
        >
          <div
            id="terms"
            style={{
              width: "0.8rem",
              height: "0.8rem",
              backgroundColor: "#fff",
              borderRadius: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
              marginRight: "1rem",
            }}
          >
            <div
              style={{
                width: "0.5rem",
                height: "0.5rem",
                backgroundColor: !check?.check2 ? "#fff" : "#00BF63",
                borderRadius: 0,
                border: "none",
              }}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              color: "white",
              fontSize: "1rem",
              textAlign: "left",
              lineHeight: "1.2",
              letterSpacing: "0.1rem",
            }}
          >
            you are consenting to the collection, processing, use, disclosure
            and storage by Coach of your personal data (including your name,
            mobile number, e-mail address) for the purpose of your{" "}
            <span
              className="linkTerms"
              onClick={() => {
                window.open(
                  "https://coachplay.apw.coach-experience.com/rsvp",
                  "_blank"
                );
              }}
            >
              Entry into the Event
            </span>{" "}
            and{" "}
            <span
              className="linkTerms"
              onClick={() => {
                window.open(
                  "https://coachplay.apw.coach-experience.com/gameLanding",
                  "_blank"
                );
              }}
            >
              Playing of the Game
            </span>{" "}
            and for Coach’s future marketing and promotions communications via{" "}
            <span
              className="linkTerms"
              onClick={() => {
                window.open("https://www.superfan.digital", "_blank");
              }}
            >
              sms, voice calls or messaging platforms
            </span>{" "}
            in accordance with Coach’s Security & policy-superfan Policy which
            is accessible at{" "}
            <span
              className="linkTerms"
              onClick={() => {
                window.open(
                  "https://malaysia.coach.com/security-and-policy-superfan",
                  "_blank"
                );
              }}
            >
              link our policy-superfan policy;
            </span>{" "}
            and
          </p>
        </div>
      </section>

      <div className="block" style={{ height: "0.5rem" }} />

      {/* Third checkbox */}
      <section
        style={{
          display: "flex",
        }}
        className="terms dontwantBold"
        id="superfanTerms"
      >
        <div
          onClick={() => {
            setCheck((pre) => {
              return {
                ...pre,
                check3: !check?.check3,
              };
            });
          }}
        >
          <div
            id="terms"
            style={{
              width: "0.8rem",
              height: "0.8rem",
              backgroundColor: "#fff",
              borderRadius: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
              marginRight: "1rem",
            }}
          >
            <div
              style={{
                width: "0.5rem",
                height: "0.5rem",
                backgroundColor: !check?.check3 ? "#fff" : "#00BF63",
                borderRadius: 0,
                border: "none",
              }}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              color: "white",
              fontSize: "1rem",
              textAlign: "left",
              lineHeight: "1.2",
              letterSpacing: "0.1rem",
            }}
          >
            you acknowledge and agree to the link the{" "}
            <span
              className="linkTerms"
              onClick={() => {
                window.open(window.origin + "/tnc/APW_Terms.pdf", "_blank");
              }}
            >
              Terms and Conditions
            </span>{" "}
            that apply to your{" "}
            <span
              className="linkTerms"
              onClick={() => {
                window.open(
                  "https://coachplay.apw.coach-experience.com/rsvp",
                  "_blank"
                );
              }}
            >
              Entry into the Event
            </span>{" "}
            and{" "}
            <span
              className="linkTerms"
              onClick={() => {
                window.open(
                  "https://coachplay.apw.coach-experience.com/gameLanding",
                  "_blank"
                );
              }}
            >
              Playing of the Game.
            </span>
          </p>
        </div>
      </section>

      {/* {lassss} */}
      <div className="block" style={{ height: "0.5rem" }} />

      <section
        style={{ display: "flex" }}
        className="terms dontwantBold"
        id="superfanTerms"
      >
        <p
          style={{
            color: "white",
            fontSize: "1rem",
            textAlign: "left",
            lineHeight: "1.2",
            letterSpacing: "0.1rem",
          }}
        >
          Individuals below the age of 18 must obtain the prior permission of a
          parent (or legal guardian) over
          {/* View More Toggle */}
          {showMore ? (
            <>
              {" "}
              the age of 18 in order to sign-up/log-in. In such circumstance,
              the contact details of such parent (or legal guardian) shall be
              provided instead and all consents and acknowledgments herein shall
              be deemed to be given in respect of the contact details of such
              parent (or legal guardian).
            </>
          ) : (
            "..."
          )}
          <span
            style={{
              color: "#00BF63",
              cursor: "pointer",
              marginLeft: "0.5rem",
              textDecoration: "underline",
            }}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "View Less" : "View More"}
          </span>
        </p>
      </section>
    </div>
  );
}

const ResponsiveContainer = ({ children }) => {
  return (
    <div
      style={{
        maxHeight: "90svh",
        overflow: "scroll",
        scrollbarWidth: "none", // For Firefox
      }}
      className="hide-scrollbar" // Add the class for WebKit browsers
    >
      {children}
      <style jsx>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none; // For Chrome and Safari
        }
      `}</style>
    </div>
  );
};
